import {
  Component,
  OnInit,
  Renderer2,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray
} from '@angular/forms';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../utils/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { log } from 'console';
declare const window: any;
@Component({
  selector: 'app-make-trip-inclusion',
  templateUrl: './make-trip-inclusion.component.html',
  styleUrls: ['./make-trip-inclusion.component.scss'],
})
export class MakeTripInclusionComponent implements OnInit {
  addInculssionForm: FormGroup =  this.formBuilder.group({
    inculssion: ['', [Validators.required]],
    summary: ['', [Validators.required]],
    exculssion: ['', [Validators.required]],
    summaryExc: ['', [Validators.required]],
    otherinfo_note: ['', []],
    mustCarry: ['', [Validators.required]],
    cancellationPolicy: ['', [Validators.required]],
    //main_menu: ['', [Validators.required]],
    multiple_url: this.formBuilder.array([this.addAdditionalInfoGroup()]),
    inculssiontitledes: this.formBuilder.array([this.addAdditionalInfoGroup()])
    // blog_image: [''],
    // status: ['', []],
  });
  public dynamicDesc: any = [];
  @ViewChild('div', { static: false }) div: ElementRef;
  id: any;
  show: any;
  object: any;
  TripData: any;
  tripId: any;
  allData: any;
  public i: number = 1;
  public isAuthLoading = false;
  public isAuthDisabled = false;
  public minDate = undefined;
  pricingid: any;
  private addAdditionalInfoGroup(): FormGroup {
    return this.formBuilder.group({
      youtube_url: []
    });
  }
 
  //Add Fields

  //Add Fields
  addAdditionalInfo(): void {
    this.additionalInfo.push(this.addAdditionalInfoGroup());
  }

  //Remove Fields
  removeAdditionalInfo(index: number): void {
    this.additionalInfo.removeAt(index);
  }

  //Fields Array
  get additionalInfo(): FormArray {
    return <FormArray>this.addInculssionForm.get('multiple_url');
  }


  //multipe inculsiion title and desc.

  private addAdditionalInfoGroupnew(): FormGroup {
    return this.formBuilder.group({
      inculssion_title: [],
      inculssion_desc: []
    });
  }
 
  //Add Fields

  //Add Fields
  addAdditionalInfonew(): void {
    this.additionalInfonew.push(this.addAdditionalInfoGroupnew());
  }

  //Remove Fields
  removeAdditionalInfonew(index: number): void {
    this.additionalInfonew.removeAt(index);
  }

  //Fields Array
  get additionalInfonew(): FormArray {
    return <FormArray>this.addInculssionForm.get('inculssiontitledes');
  }

  config_ck = {
    uiColor: '#ffffff',
    toolbarGroups: [
      { name: 'clipboard', groups: ['clipboard', 'undo'] },
      { name: 'editing', groups: ['find', 'selection', 'spellchecker'] },
      { name: 'links' },
      { name: 'insert' },
      { name: 'document', groups: ['mode', 'document', 'doctools'] },
      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
      { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align'] },
      { name: 'styles' },
      { name: 'colors' },
    ],
    // skin: 'kama',
    resize_enabled: false,
    removePlugins: 'elementspath,save,magicline',
    //extraPlugins: 'justify',
    colorButton_foreStyle: {
      element: 'font',
      attributes: { color: '#(color)' },
    },
    height: 188,
    removeDialogTabs: 'image:advanced;link:advanced',
    removeButtons: 'Subscript,Superscript,Anchor,Source,Table',
    format_tags: 'p;h1;h2;h3;pre;div',
    extraPlugins: 'uploadimage, justify',
     /* uploadUrl:
        'https://ckeditor.com/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Files&responseType=json',

      // Configure your file manager integration. This example uses CKFinder 3 for PHP.
      filebrowserBrowseUrl:
        'https://ckeditor.com/apps/ckfinder/3.4.5/ckfinder.html',
      filebrowserImageBrowseUrl:
        'https://ckeditor.com/apps/ckfinder/3.4.5/ckfinder.html?type=Images',
      filebrowserUploadUrl:
        'https://ckeditor.com/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Files',
      filebrowserImageUploadUrl:
        'https://ckeditor.com/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Images'*/
  };
 

  constructor(
    private formBuilder: FormBuilder,
    private config: NgbDatepickerConfig,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2,
    private apiService: ApiService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.object = this.route.snapshot.paramMap.get('objId');
    if (this.object != undefined && this.object != null && this.object != '') {
      this.edittripfour(this.object);
    }
    // this.addInculssionForm = new FormGroup({
    //   inculssion: new FormControl(),
    //   summary: new FormControl(),
    //   exculssion: new FormControl(),
    //   summaryExc: new FormControl(),
    //   multiple_youtubeurl: this.formBuilder.array([this.addAdditionalInfoGroup()])
    // });
    // this.addInculssionForm = this.formBuilder.group({
    //   inculssion: ['', [Validators.required]],
    //   summary: ['', [Validators.required]],
    //   exculssion: ['', [Validators.required]],
    //   summaryExc: ['', [Validators.required]],
    //   multiple_youtubeurl: ['', [Validators.required]],
    //   // blog_image: [''],
    //   // status: ['', []],
    // });
  }

  // addElement() {
  //   const p: HTMLParagraphElement = this.renderer.createElement('div');
  //   p.innerHTML = `
  //     <tr id="row${this.i}"><td><label class="col-md-3 control-label">Description</label><textarea class="ckeditor form-control" formControlName="incDesc${this.i}" id="incDesc${this.i}" rows="6" style="width:50%;" value=""></textarea></td></tr>
  //   `;

  //   this.renderer.appendChild(this.div.nativeElement, p);
  //   window.CKEDITOR.replace('incDesc' + this.i);
  //   this.addInculssionForm.addControl(
  //     `incDesc${this.i}`,
  //     new FormControl('', Validators.required)
  //   );
  //   this.i++;
  // }

  edittripfour(id) {
    let dataForForm = {
      id: id,
    };
    //console.log(this.object);
    this.spinner.show()
    this.apiService.tourUpdate(dataForForm).subscribe((result: any) => {
      console.log(result.getData);
      console.log(result.getData?.summary, 'hiiiiiiiiiiiiii');
      if (result.success) {
        this.spinner.hide();
        this.allData = result?.getData
        this.pricingid = result?.getData?.isTourCreatedCompletely;
        let TripData: any = result.getData;
        this.show = TripData.inculssion
        this.tripId = TripData._id;
        this.addInculssionForm.patchValue({
          inculssion: TripData?.inculssion,
          exculssion: TripData?.exculssion,
          summary: TripData.summary[0],
          summaryExc: TripData?.summaryExc[0],
          otherinfo_note: TripData?.otherinfo_note,
          mustCarry: TripData?.mustCarry?.[0] ?? [],
          cancellationPolicy: TripData?.cancellationPolic?.[0] ?? [],         
        });
        // console.log(TripData.otherinfo_note,">>>>>>>>>>>>");
        
        while (this.additionalInfonew.length !== 0) {
          this.additionalInfonew.removeAt(0);
        }
         const controlnew: any = <FormArray>this.addInculssionForm.controls['inculssiontitledes'];
         // Iterate through the 'multiple_overviewdesc' array and add form groups to the form array
         TripData.inculssiontitledes.forEach((element: any) => {
           let fg = this.formBuilder.group({
            inculssion_desc: [element.inculssion_desc, [Validators.required]],
            inculssion_title: [element.inculssion_title, [Validators.required]],
           });
           controlnew.push(fg);
         });

         while (this.additionalInfo.length !== 0) {
          this.additionalInfo.removeAt(0);
        }
         const control: any = <FormArray>this.addInculssionForm.controls['multiple_url'];
         // Iterate through the 'multiple_overviewdesc' array and add form groups to the form array
         TripData.multiple_url.forEach((element: any) => {
           let yt = this.formBuilder.group({
            youtube_url: [element.youtube_url, [Validators.required]],
            //inculssion_title: [element.inculssion_title, [Validators.required]],
           });
           control.push(yt);
         });
        // TripData.InculsionDesc.forEach((key, i) => {
        //      this.addElement();
        //     console.log(TripData.InculsionDesc);
        //     //this.addMakeTripTwoForm.controls['desc' + this.i]?.setValue(key.desc);
        //   });

        // setTimeout(() => {
        //  // CKEDITOR.instances[desc${this.i}].setData(TripData.Overwiew1)
        //   TripData.InculsionDesc.forEach((key, x) => {
        //     console.log(key.incDesc, 'incDesc' + (x+1));
        //     window.CKEDITOR.instances[`incDesc${x+1}`].setData(key.incDesc)
        //     this.addInculssionForm.controls[`incDesc${x+1}`].setValue(key.incDesc);
        //     console.log(this.addInculssionForm.value, "ctrl");
        //     console.log(window.CKEDITOR.instances, 'ck');
        //   });
        // }, 2000);
      }else{
        this.spinner.show();
      }
    });
  }

  addMakeTourFour(id) {
    if (
      this.addInculssionForm.value.inculssion == undefined ||
      this.addInculssionForm.value.inculssion == ''
    ) {
      Swal.fire({
        text: 'Please enter inclusion',
        icon: 'warning',
      });
      return false;
    }
    if (
      this.addInculssionForm.value.summary == undefined ||
      this.addInculssionForm.value.summary == ''
    ) {
      Swal.fire({
        text: 'Please enter summary',
        icon: 'warning',
      });
      return false;
    }
    if (
      this.addInculssionForm.value.exculssion == undefined ||
      this.addInculssionForm.value.exculssion == ''
    ) {
      Swal.fire({
        text: 'Please enter exculssion',
        icon: 'warning',
      });
      return false;
    }
    if (
      this.addInculssionForm.value.summaryExc == undefined ||
      this.addInculssionForm.value.summaryExc == ''
    ) {
      Swal.fire({
        text: 'Please enter summary',
        icon: 'warning',
      });
      return false;
    }
    // if (
    //   this.addInculssionForm.value.otherinfo_note == undefined ||
    //   this.addInculssionForm.value.otherinfo_note == ''
    // ) {
    //   Swal.fire({
    //     text: 'Please enter Other Info Note',
    //     icon: 'warning',
    //   });
    //   return false;
    // }
    //this.dynamicDesc = [];
    // for (let i in window.CKEDITOR.instances) {
    //   if (i.substring(0, 6) !== 'editor') {
    //     let item = {};
    //     item[i.slice(0, -1)] = window.CKEDITOR.instances[i].getData();
    //     // list.push(item);
    //     this.dynamicDesc.push(item);
    //   }
    // }
    //console.log(this.dynamicDesc);
    //this.addInculssionForm.value.InculsionDesc = this.dynamicDesc;
    // console.log(this.addInculssionForm.value);
    //return;
    this.isAuthLoading = true;
    this.isAuthDisabled = true;
    // for (let i in window.CKEDITOR.instances) {
    //   window.CKEDITOR.instances[i].destroy();
    // }
    this.spinner.show()
    this.apiService
      .updateTourFour(this.addInculssionForm.value, this.object)
      .subscribe((res: any) => {
        //console.log(this.tripInfo._id)
        //return;
        // console.log(res);
        if (res.success) {
          this.spinner.hide()
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          //this.toastr.success(res.message);
          // Swal.fire({
          //   text: res.message,
          //   icon: 'success',
          // });
          this.router.navigateByUrl('/make-trip-pricing-list/' + this.object);
          // console.log(this.object);
        } else {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          //this.toastr.error(res.message);
          Swal.fire({
            text: res.message,
            icon: 'error',
          });
        }
      });
  }
}
